import * as React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import CriticalHead from "../components/CriticalHead"

import emblem from '../images/emblem.svg'

const NotFoundPage = () => (
  <Layout>
    <CriticalHead title="404: Not found" />
    <section id="no-page-section">
      <Link id="emblem-link" to="/">
        <img id='emblem' src={emblem} alt='Эмблема территории «Ландшафт»' />
      </Link>
      <h1>Ошибка 404:</h1>
      <p>запрашиваемая страница не найдена</p>
      <Link className="button" to="/">Перейти на главную</Link>
    </section>
  </Layout>
)

export default NotFoundPage
